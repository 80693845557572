
















import { getProList } from '@/api/product';
import { DictItem, getDictConfig } from '@/api/other';
import BaseList from '@/components/Base/BaseList/index.vue';
import ProductCard from '@/components/Base/ProductCard/index.vue';
import { Vue, Component } from 'vue-property-decorator';
import { isEmpty } from 'lodash';
@Component({
  name: '',
  components: { BaseList, ProductCard }
})
export default class extends Vue {
  recommendTabs: DictItem[] = [];
  activeTab = 0;

  get api() {
    return getProList;
  }

  getParam(curItem: DictItem) {
    if (!isEmpty(curItem)) {
      const itemContent = JSON.parse(curItem.itemContent) as { queryData: string };

      const queryData = JSON.parse(itemContent.queryData) as { catIdList: string[]; tagCode: string };
      return queryData;
    }
    return {};
  }

  // 获取商品推荐tab
  async getRecommendTab() {
    const { payload } = await getDictConfig('RECOMMEND');
    if (!payload.length) return;
    this.recommendTabs = payload;
  }

  created() {
    this.getRecommendTab();
  }
}
