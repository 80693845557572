





























import { DictItem, getDictConfig } from '@/api/other';
import { Vue, Component } from 'vue-property-decorator';
import Notice from './comm/Notice.vue';
import Recommend from './comm/Recommend.vue';
import HeaderSearch from '@/components/Base/HeaderSearch/index.vue';

@Component({
  name: 'BaseIndex',
  components: { Notice, Recommend, HeaderSearch }
})
export default class extends Vue {
  bannerList: DictItem[] = [];
  vajraArea: DictItem[] = [];

  searchPageShow = false;

  isUrl(e: string): boolean {
    let reg = /^(?:(http|https|ftp):\/\/)?((?:[\w-]+\.)+[a-z0-9]+)((?:\/[^/?#]*)+)?(\?[^#]+)?(#.+)?$/i;
    return reg.test(e);
  }

  handleClickGrid(url: string) {
    if (!url) {
      return this.$toast('敬请期待！');
    }

    if (this.isUrl(url)) {
      window.location.href = url;
      return;
    }
    this.$router.push(url);
  }

  async getBanner() {
    const { payload } = await getDictConfig('BANNER');
    this.bannerList = payload;
  }

  async getVajraArea() {
    const { payload } = await getDictConfig('VAJRA_AREA');
    this.vajraArea = payload;
  }

  created() {
    document.title = '源源商城';
    this.getBanner();
    this.getVajraArea();
  }
}
