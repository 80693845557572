




















import { DictItem, getHomeNotice } from '@/api/other';
import { Vue, Component } from 'vue-property-decorator';
@Component({
  name: ''
})
export default class extends Vue {
  noticeList: DictItem[] = [];
  current = 0;

  async getNotice() {
    const { payload } = await getHomeNotice();
    this.noticeList = payload.news || [];
  }

  onChangeSwiper(index: number) {
    this.current = index;
  }

  handleClickNotice() {
    const { itemCode } = this.noticeList[this.current];
    this.$router.push('news/' + itemCode);
  }

  created() {
    this.getNotice();
  }
}
